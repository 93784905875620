/* eslint-disable react/react-in-jsx-scope */
import React from 'react';
import moment from 'moment';
import parseInt from 'lodash/parseInt';
import toLower from 'lodash/toLower';
import isArray from 'lodash/isArray';
import Notification from '../components/Notification/Notification';
import { getSkillsList, getQuerySkills, getTimeZones } from '../service';
import {
  APIs,
  CANDIDATE,
  CANDIDATE_COLOR,
  CANDIDATE_TEXT,
  FULLTIME_TEXT,
  JOB_TYPE,
  MASTER_ADMIN_COLOR,
  ORGANIZER,
  ORGANIZER_COLOR,
  ORGANIZER_TEXT,
  PARTTIME_TEXT,
  RECRUITER,
  RECRUITER_COLOR,
  RECRUITER_TEXT,
  STATUS_ACTIVE,
  STATUS_ACTIVE_TEXT,
  STATUS_DISABLED_TEXT,
} from '../constants';
import {
  getAllCountryPhoneCodes,
  getAllCoutries,
  getAllCurrencies,
  getAllIndustries,
  getAllNationalities,
  getStatesByCountry,
} from '../graphql/queries';
import { Theme } from '../enums';
import { COLORS } from '../styles/constants/colors';
import LiveIcon from '../assets/icons-and-sets/broadcast.svg';
import { Tags } from '../components/shared-components/Tags';
import {
  checkIfUserIsCandidate,
  checkIfUserIsMasterAdmin,
  checkIfUserIsOrganizer,
  checkIfUserIsRecruiter,
  checkIfUserIsSuperAdmin,
} from './userInfo';
import {
  AdminRoutes,
  CandidateRoutes,
  OrganizerRoutes,
  RecruiterRoutes,
  SuperAdminRoutes,
} from '../constants/routes';
import config from '../config';
import { get } from 'lodash';
import getNow from './getNow';

export const getNotificationCircleColor = (theme, color) => {
  switch (theme) {
    case CANDIDATE_TEXT.toLowerCase():
      return COLORS.MOODY_BLUE;

    case ORGANIZER_TEXT.toLowerCase():
      return COLORS.PEARL_AQUA;

    case RECRUITER_TEXT.toLowerCase():
      return COLORS.SALOMIE;

    default:
      return color;
  }
};

export const serialNoColumn = {
  title: 'S.No',
  key: 'serialNo',
  dataIndex: 'serialNo',
  width: 100,
};

export const getSerialNumber = (page = 1, limit = 10, rowId = 0) => {
  return (page - 1) * limit + (rowId + 1);
};

export const getUser = (role) => {
  switch (role) {
    case CANDIDATE:
      return 'candidate';

    case ORGANIZER:
      return 'organizer';

    case RECRUITER:
      return 'recruiter';

    default:
      return '';
  }
};

export const getCircleColor = (theme) => {
  switch (toLower(theme)) {
    case Theme.Candidate:
      return COLORS.LAVENDER;

    case Theme.Organizer:
      return COLORS.SINBAD;

    case Theme.Recruiter:
      return COLORS.SALOMIE;

    default:
      return '';
  }
};

export const getTextColor = (color) => {
  switch (color) {
    case 'medium-grey':
      return COLORS.ARGENT;
    case 'orange':
      return COLORS.DUTCH_WHITE;
    case 'violet':
      return COLORS.OLD_LAVENDER;
    case 'blue':
      return COLORS.SLATE_BLUE;
    case 'red':
      return COLORS.TAPESTRY;
    default:
      return '';
  }
};

export const getTheme = (theme) => {
  switch (theme) {
    case Theme.Candidate:
      return CANDIDATE_COLOR;

    case Theme.Organizer:
      return ORGANIZER_COLOR;

    case Theme.Recruiter:
      return RECRUITER_COLOR;

    case Theme.MasterAdmin:
      return MASTER_ADMIN_COLOR;

    case Theme.Danger:
      return COLORS.ORANGE_RED;

    case Theme.Warning:
      return COLORS.ORANGE_YELLOW;

    default:
      return COLORS.DODGER_BLUE;
  }
};

export const getThemeByRoleId = (roleId, isSignUpModal = false) => {
  switch (roleId) {
    case CANDIDATE:
      return isSignUpModal ? Theme.MasterAdmin : Theme.Candidate;
    case ORGANIZER:
      return Theme.Organizer;
    case RECRUITER:
      return Theme.Recruiter;
    default:
      return Theme.MasterAdmin;
  }
};

export const getEventStatusText = (status) => {
  switch (status) {
    case 'live':
      return 'Live Event';

    default:
      return '';
  }
};

export const getEventStatusIcon = (status) => {
  switch (status) {
    case 'live':
      return LiveIcon;

    default:
      return '';
  }
};

export const getUserStatusText = (status) => {
  return STATUS_ACTIVE === status ? STATUS_ACTIVE_TEXT : STATUS_DISABLED_TEXT;
};

export const getColorByStatus = (status) => {
  return STATUS_ACTIVE === status ? COLORS.primaryColor : COLORS.CUSTOM_RED;
};

export const getCountryCodeData = async (addAuthHeader = true) => {
  const countriesCode = await getAllCountryPhoneCodes(addAuthHeader);
  if (countriesCode) {
    return countriesCode.map((item, id) => {
      return { id, name: `${item.code} ${item.countryName}` };
    });
  }
  return [];
};

export const getDomainsData = async (addAuthHeader = true) => {
  const industriesList = await getAllIndustries(addAuthHeader);
  if (industriesList) {
    return industriesList;
  }
  return [];
};

export const getCurrenciesData = async (addAuthHeader = true) => {
  const currencies = await getAllCurrencies(addAuthHeader);
  if (currencies) {
    return currencies.map((item) => {
      const name = `${item.countryName} (${item.code})`;
      return {
        id: Number(item.id),
        value: item.code,
        name,
        usdRate: item.usdRate,
      };
    });
  }
  return [];
};

export const getNationalityData = async (addAuthHeader = true) => {
  const nationalitiesList = await getAllNationalities(addAuthHeader);
  if (nationalitiesList) {
    return nationalitiesList;
  }
  return [];
};

export const getSkillsData = async () => {
  const skillsList = await getSkillsList();
  if (skillsList) {
    return skillsList.map((item) => {
      return { id: item.id, name: item.skill };
    });
  }
  return [];
};

export const getUrlVars = (path) => {
  const vars = {};
  path.replace(/[?&]+([^=&]+)=([^&]*)/gi, (m, key, value) => {
    vars[key] = value.replace('%20', ' ');
  });
  return vars;
};

export const getPicture = (fileName) => {
  return fileName ? `${config.baseURL}${APIs.index_file}${fileName}/` : null;
};

export const getCountriesData = async (addAuthHeader = true) => {
  const countriesList = await getAllCoutries(addAuthHeader);
  if (countriesList) {
    return countriesList;
  }
  return [];
};

export const getStatesData = async (country, addAuthHeader = true) => {
  const statesList = await getStatesByCountry(country, addAuthHeader);
  if (statesList) {
    return statesList;
  }
  return [];
};

export const getQuerySkillsData = async (words) => {
  const skillsList = await getQuerySkills(words);
  if (skillsList) {
    return skillsList.map((item) => {
      return { id: item.id, name: item.skill };
    });
  }
  return [];
};

export const getTimeZonesData = async () => {
  const timeZoneList = await getTimeZones();
  if (timeZoneList) {
    return timeZoneList.map((item, id) => {
      return { id, label: item.label, name: item.value };
    });
  }
  return [];
};

/**
 * Invoke error notification with imageType for rejected images
 * @param {string} imageType
 */
const rejectImageNotification = (imageType) => {
  Notification(
    'error',
    imageType,
    'Image doesn’t match the requirements. Please select another image.',
  );
};

/**
 * Check if minimum height & width meets criteria
 * Based on that it invokes resolve or reject function
 * @param {number} height
 * @param {number} width
 * @param {object} ACCEPTED_IMAGE
 * @param {string} imageType
 * @param {function} notificationCb
 * @param {function} resolve
 * @param {function} reject
 */
const checkMinValidation = (
  height,
  width,
  ACCEPTED_IMAGE,
  imageType,
  notificationCb,
  resolve,
  reject,
) => {
  if (width < ACCEPTED_IMAGE.minWidth || height < ACCEPTED_IMAGE.minHeight) {
    notificationCb(imageType);
    reject(false);
  }
  resolve(true);
};

/**
 * Check if minimum & maximum height, width meets criteria
 * Based on that it invokes resolve or notificationCb reject & function
 * @param {number} height
 * @param {number} width
 * @param {object} ACCEPTED_IMAGE
 * @param {string} imageType
 * @param {function} notificationCb
 * @param {function} resolve
 * @param {function} reject
 */
const checkMinMaxValidation = (
  height,
  width,
  ACCEPTED_IMAGE,
  imageType,
  notificationCb,
  setErrorCoverImage,
  resolve,
  reject,
) => {
  if (width < ACCEPTED_IMAGE.minWidth || height < ACCEPTED_IMAGE.minHeight) {
    setErrorCoverImage(true);
    notificationCb(imageType);
    reject(false);
  }
  resolve(true);
};

/**
 * Checks if file types meets criteria
 * Based on that it invokes notificationCb & reject function or not
 * @param {*} ACCEPTED_IMAGE
 * @param {*} imageType
 * @param {*} notificationCb
 * @param {*} file
 * @param {*} reject
 */
const fileTypeValidation = (
  ACCEPTED_IMAGE,
  imageType,
  notificationCb,
  file,
  reject,
) => {
  if (!ACCEPTED_IMAGE.fileTypes.includes(file.type)) {
    notificationCb(imageType);
    reject(false);
  }
};

/**
 * It accepts object value and utilise to validate image type
 * @param {object} validation
 * @returns
 */
export const imageValidator = (validation) => {
  const { ACCEPTED_IMAGE, file, imageType, setErrorCoverImage } = validation;

  return new Promise((resolve, reject) => {
    // Validate image size, resolution & file type

    // File type validation
    fileTypeValidation(
      ACCEPTED_IMAGE,
      imageType,
      rejectImageNotification,
      file,
      reject,
    );

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.addEventListener('load', (event) => {
      const _loadedImageUrl = event.target.result;
      const image = document.createElement('img');
      image.src = _loadedImageUrl;
      image.addEventListener('load', () => {
        const { width, height } = image;
        // set image width and height to your state here
        switch (imageType) {
          case 'Cover':
            // Minimum & maximum height & width validation
            checkMinMaxValidation(
              height,
              width,
              ACCEPTED_IMAGE,
              imageType,
              rejectImageNotification,
              setErrorCoverImage,
              resolve,
              reject,
            );
            break;
          default:
            // Minimum height & width validation
            checkMinValidation(
              height,
              width,
              ACCEPTED_IMAGE,
              imageType,
              rejectImageNotification,
              resolve,
              reject,
            );
        }
      });
    });
  });
};

export const getTextFromHTML = (html) => {
  const div = document.createElement('div');
  div.innerHTML = html;
  const text = div.textContent || div.innerText || '';
  return text;
};

export const getEventTime = (time) => {
  const startOfTime = moment
    .unix(time.dateTime)
    .format(`MMM Do, YYYY, ddd h:mm a`);
  const endOfTime = moment
    .unix(time.endDateTime)
    .format('MMM Do, YYYY, ddd h:mm a');
  return { startOfTime, endOfTime };
};

export function isJobTypeFullTime(job, idx = 0) {
  if (parseInt(job) === JOB_TYPE.FULL_TIME) {
    return (
      <Tags key={idx} color={COLORS.WHISPER} textColor={COLORS.RICH_BLUE}>
        {FULLTIME_TEXT}
      </Tags>
    );
  }
  return (
    <Tags key={idx} color={COLORS.WHISPER} textColor={COLORS.RICH_BLUE}>
      {PARTTIME_TEXT}
    </Tags>
  );
}

const getYearsAndMonthsString = (years, months) => {
  const year =
    years === 0 && months === 0
      ? 'N/A'
      : years === 1
      ? `${years} year`
      : years > 1
      ? `${years} years`
      : '';
  const month =
    months === 1 ? ` ${months} month` : months > 1 ? ` ${months} months` : '';
  return `${year} ${month}`;
};

export function getYearsAndMonths(jobDetails) {
  const totalMonths = jobDetails.minExperience;
  if (totalMonths === 0) return 'N/A';
  const years = Math.floor(totalMonths / 12);
  const months = totalMonths % 12;
  return getYearsAndMonthsString(years, months);
}

export const redirectToProperUserRole = (roleId) => {
  if (roleId) {
    switch (true) {
      case checkIfUserIsOrganizer(roleId):
        window.location.assign(`${config.appURL}${OrganizerRoutes.organizer}`);
        break;
      case checkIfUserIsRecruiter(roleId):
        window.location.assign(`${config.appURL}${RecruiterRoutes.recruiter}`);
        break;
      case checkIfUserIsCandidate(roleId):
        window.location.assign(`${config.appURL}${CandidateRoutes.candidate}`);
        break;
      case checkIfUserIsMasterAdmin(roleId):
        window.location.assign(`${config.appURL}${AdminRoutes.dashboard}`);
        break;
      case checkIfUserIsSuperAdmin(roleId):
        window.location.assign(`${config.appURL}${SuperAdminRoutes.dashboard}`);
        break;
      default:
        break;
    }
  }
};

/**
 * Return a className in the form of x-feature-tour.
 * @param {string} name
 * @returns string
 */
export const makeClassNameForFeatureTour = (name) => {
  return name.replace(/\s/g, '-').concat('-feature-tour').toLowerCase();
};

export const processCategory = (initialCategoryList, categories) => {
  const list = categories;
  const deleteList = initialCategoryList.filter(
    (item) => isArray(list) && !list.includes(item.categoryId),
  );
  const finalList = list.map((categoryId) => {
    const existing = initialCategoryList.find(
      (o) => +o.categoryId === +categoryId,
    );
    return existing
      ? null
      : {
          ...(existing && { id: existing.id }),
          operation: existing ? 'edit' : 'add',
          categoryId,
        };
  });

  const finalDeletedList = deleteList.map((item) => {
    return {
      id: item.id,
      operation: 'delete',
      categoryId: +item.categoryId,
    };
  });
  return finalList.concat(finalDeletedList).filter((item) => !!item);
};

export const getCategoryOptions = (categoriesOptions = []) => {
  return categoriesOptions.map((item) => ({
    id: item.id,
    name: item.name,
    value: item.id,
  }));
};

export const getCategoryOptionsByLabel = (categoriesOptions = []) => {
  return categoriesOptions.map((item) => ({
    id: item.id,
    label: item.name,
    value: item.id,
  }));
};

export const getTimeFromTimeStamp = (time) => {
  return moment.unix(time).format('hh:mm A');
};

export const removeDuplicates = (str = '', selectedChar = '-') => {
  if (!str.length) return str;
  let lastChar = str[0];
  let finalT = str[0];
  for (const char of str.slice(1)) {
    if (char !== selectedChar) {
      finalT += char;
    } else if (lastChar !== char) finalT += char;
    lastChar = char;
  }
  return finalT;
};

export const getParsedEventName = (name = '') => {
  return removeDuplicates(
    name.replace(/[#| |:|-|/|\\|,|–|%|$|&|^|@|!|*|?]/g, '-').toLowerCase(),
  );
};

export const addThreeDaysInUnixTime = (data) => {
  const communityEventEtaUnix = get(
    data,
    'meta.forwardedTimestamp',
    get(data, 'updatedAt', getNow()),
  );
  return moment.unix(communityEventEtaUnix).add(3, 'days').unix();
};
