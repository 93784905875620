import config from '../config';
import { getParsedEventName } from '../utils/common';

export const marketingWebsite = {
  home: 'https://www.recorem.com',
};

export const Routes = {
  superAdmin: '/super-admin',
  masterAdmin: '/master-admin',
  organizer: '/organizer',
  recruiter: '/recruiter',
  candidate: '/candidate',
  login: '/login',
  adminLogin: '/admin/login',
  signupInvite: '/signup/invite',
  verify: '/verify',
  resetPassword: '/reset-password',
  commingSoon: '/comming-soon/',
  createPassword: `/create-password`,
  unsubscribeInviteMail: `/invite/event/unsubscribe`,
  inviteEvent: `/invite/event`,
  viewPublicEvent: '/public/event/:id',
  exploreEvents: '/explore-events',
  unsubscribe: '/unsubscribe',
  communityPage: `/event-partner/:companyId/community`,
};

export const OrganizerRoutes = {
  events: '/organizer/events/',
  organizer: '/organizer/',
  attendees: '/organizer/attendees/',
  earnings: '/organizer/earnings/',
  chat: '/organizer/chat/',
  groupChat: '/organizer/chat/chatroom',
  broadcast: '/organizer/broadcast/',
  calender: '/organizer/calender/',
  refund: '/organizer/refund/',
  createEvent: '/organizer/events/create/',
  viewEvent: (id) => `/organizer/events/view/${id}`,
  profile: '/organizer/profile/',
  profileOnboard: '/organizer/profile-onboard',
  profileCrew: (id) => `/organizer/crew-member-profile/${id}`,
  editEvent: (id, query) =>
    `/organizer/events/edit/${id}${query ? `?${query}` : ''}`,
  notifications: '/organizer/notifications',
  support: '/organizer/support',
  helpDoc: '/organizer/helpdoc',
  bulkInvite: '/organizer/bulk-invite',
  changePassword: '/organizer/password',
  paymentStatus: '/organizer/payment/status',
  communityAccess: `/organizer/community-access-request/`,
};

export const RecruiterRoutes = {
  recruiter: '/recruiter/',
  event: '/recruiter/events/',
  recruit: '/recruiter/recruit/',
  calender: '/recruiter/calender/',
  chat: '/recruiter/chat/',
  groupChat: '/recruiter/chat/chatroom',
  viewEvent: (id) => `/recruiter/events/view/${id}`,
  exploreEvent: '/recruiter/events/explore-events/',
  profile: '/recruiter/profile/',
  profileOnboard: '/recruiter/profile-onboard',
  profileCrew: (id) => `/recruiter/crew-member-profile/${id}`,
  organizerInfo: '/recruiter/organizer-profile-info',
  viewResume: '/recruiter/resume/:jobId/:applicationId/:eventId',
  createJob: '/recruiter/jobs',
  editJob: '/recruiter/jobs/:id',
  jobOpportunities: '/recruiter/recruit/job-opportunities/',
  viewJobDetails: '/recruiter/job-opportunities/view-job-details/:id',
  notifications: '/recruiter/notifications',
  candidateProfile: (id) => `/recruiter/job-application/candidate/${id}`,
  support: '/recruiter/support',
  helpDoc: '/recruiter/helpdoc',
  changePassword: '/recruiter/password',
  paymentStatus: '/recruiter/payment/status',
  pricingPlan: '/recruiter/pricing-plan',
};

export const CandidateRoutes = {
  candidate: '/candidate/',
  event: '/candidate/events/',
  job: '/candidate/job/',
  jobs: '/candidate/jobs/',
  calender: '/candidate/calender/',
  chat: '/candidate/chat/',
  groupChat: '/candidate/chat/chatRoom',
  viewEvent: (id) => `/candidate/events/view/${id}`,
  exploreEvent: '/candidate/events/explore-events/',
  profile: '/candidate/profile/',
  profileOnboard: '/candidate/profile-onboard',
  profileQuickStart: '/candidate/quick-start',
  notifications: '/candidate/notifications',
  jobDetails: '/candidate/job/:id/:eventId',
  organizerInfo: '/candidate/organizer-details',
  support: '/candidate/support',
  helpDoc: '/candidate/helpdoc',
  changePassword: '/candidate/password',
  paymentStatus: '/candidate/payment/status',
  jobResults: '/candidate/job-result/',
  myNfts: '/candidate/my-nfts',
};

export const MainWebsiteRoutes = {
  home: config.mainWebsiteUrl,
  privacyPolicy: `${config.mainWebsiteUrl}/privacy-policy`,
  data: `${config.mainWebsiteUrl}/data-protection`,
  termsAndConditions: `${config.mainWebsiteUrl}/terms-and-conditions`,
  gdpr: `${config.mainWebsiteUrl}/data-protection`,
  events: `${config.mainWebsiteUrl}/explore-events`,
  howItWorks: `${config.mainWebsiteUrl}/#how-it-works`,
  organizer: `${config.mainWebsiteUrl}/organizer`,
  recruiter: `${config.mainWebsiteUrl}/recruiter`,
  aboutUs: `${config.mainWebsiteUrl}/about-us`,
  blog: `${config.mainWebsiteUrl}/blog`,
};

export const PublicRoutes = {
  publicEvent: (id, name, jobId, isShared) =>
    `${MainWebsiteRoutes.home}/spectator-mode/${id}/${getParsedEventName(
      name,
    )}${jobId ? `?jobId=${jobId}` : isShared ? `?isShared=${isShared}` : ''}`,
};

export const AdminRoutes = {
  dashboard: '/master-admin/',
  signup: '/master-admin/signup',
  candidate: {
    candidates: '/master-admin/candidate/',
    candidateOverview: '/master-admin/candidate/candidate-overview',
    candidateProfile: '/master-admin/candidate/candidate-profile',
  },
  organizer: {
    organizers: '/master-admin/organizer/',
    organizerInformation: '/master-admin/organizer/organizer-info/:companyId',
    manageCompanies: `/master-admin/organizer/manage-companies`,
    mergedCompaniesHistory: `/master-admin/organizer/manage-companies/merged-history`,
  },
  notifications: '/master-admin/notification',
  coupons: '/master-admin/coupons',
  event: {
    eventInfo: '/master-admin/events/event-info',
    event: '/master-admin/events/',
    eventApplication: '/master-admin/events/application',
    matchCandidates: `/master-admin/events/match-candidates/`,
    broadcast: '/master-admin/events/broadcast',
  },
  recruiter: {
    recruiters: `/master-admin/recruiter/`,
    recruiterOverview: `/master-admin/recruiter/recruiter-overview`,
    recruiterInformation: `/master-admin/recruiter/recruiter-info`,
    manageCompanies: `/master-admin/recruiter/manage-companies`,
    mergedCompaniesHistory: `/master-admin/recruiter/manage-companies/merged-history`,
  },
  resumeSearch: '/master-admin/resume-search',
  skill: {
    skills: '/master-admin/skills/',
  },
  skillsNormalization: {
    index: '/master-admin/skills-normalization/',
    mergeHistory: '/master-admin/skills-normalization/merge-history',
    viewSkillMergeHistory:
      '/master-admin/skills-normalization/merge-history/:primarySkillId',
    skillDemandSupply:
      '/master-admin/skills-normalization/event/unmatched-candidates/',
  },
  bulkInvite: '/master-admin/bulk-invite',
  snoozeEvents: '/master-admin/snooze-events',
  // manageMergeCompanies: '/master-admin/manage-merge-companies/',
  generalSetting: `/master-admin/general-setting/`,
  attendeesManagement: '/master-admin/attendees-management/',
  job: {
    jobs: `/master-admin/jobs/`,
  },
};

export const SuperAdminRoutes = {
  dashboard: '/super-admin/',
  userManagement: '/super-admin/users/',
  profile: '/super-admin/profile/',
};
